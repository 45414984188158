import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular/standalone';
import { AnalyticsEventType, ModalViewEvents } from '../../services/analytics/analytics.model';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { ButtonComponent } from '../../components/button/button.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-delete-account-modal',
  templateUrl: './delete-account-modal.page.html',
  styleUrls: ['./delete-account-modal.page.scss'],
  standalone: true,
  imports: [ButtonComponent, TranslateModule],
})
export class DeleteAccountModalPage {
  constructor(
    private router: Router,
    private modalController: ModalController,
    private analyticsService: AnalyticsService,
  ) {}

  ionViewDidEnter(): void {
    this.analyticsService.sendEvent(AnalyticsEventType.InScreen, { screenName: ModalViewEvents.DeleteAccountModal });
  }

  closeModal(): void {
    void this.modalController.dismiss();
  }

  goToAccountDeletion(): void {
    void this.modalController.dismiss();
    void this.router.navigate(['delete-account']);
  }
}
