import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { Platform } from '@ionic/angular/standalone';

@Injectable({ providedIn: 'root' })
export class BrowserService {
  constructor(private platform: Platform) {}

  goTo(url: string): void {
    if (this.platform.is('cordova')) {
      void Browser.open({ url });
    } else {
      window.open(url, '_blank');
    }
  }
}
