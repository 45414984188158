import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { UserService } from '../../services/user.service';
import { AnalyticsEventType, ModalViewEvents } from '../../services/analytics/analytics.model';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { ButtonComponent } from '../../components/button/button.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-logout-confirmation-modal',
  templateUrl: './logout-confirmation-modal.page.html',
  styleUrls: ['./logout-confirmation-modal.page.scss'],
  standalone: true,
  imports: [ButtonComponent, TranslateModule],
})
export class LogoutConfirmationModalPage {
  constructor(
    private modalController: ModalController,
    private userService: UserService,
    private analyticsService: AnalyticsService,
  ) {}

  ionViewDidEnter(): void {
    this.analyticsService.sendEvent(AnalyticsEventType.InScreen, { screenName: ModalViewEvents.LogoutConfirmationModal });
  }

  closeModal(): void {
    void this.modalController.dismiss();
  }

  logout(): void {
    void this.userService.logout();
    void this.modalController.dismiss();
  }
}
